import { CircularProgress } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Transition from "../Components/RouterTransition/transition";
import Header from "../Components/Header/header";
import "../index.sass";

const Loading = () => {
    return (
        <div
            className="center_align_container"
            style={{
                height: "100vh",
                backgroundColor: "#14141f",
            }}
        >
            <CircularProgress style={{ color: "white" }} />
        </div>
    );
};

const HomePageRef = lazy(() => import("../Components/Home/homePage"));
const PrivacyPolicyRef = lazy(() => import("../Components/PrivacyPolicy/policy"));
const TermsAndConditionsRef = lazy(() => import("../Components/Terms&Conditions/t&c"));
const RiskDisclaimerRef = lazy(() => import("../Components/RiskDisclaimer/disclaimer"));
const ContentCreatorRef = lazy(() => import("../Components/Content-Creator/content-creator"));
const RoadMapRef = lazy(() => import("../Components/Roadmap/roadmap"));
const GamePlayRef = lazy(() => import("../Components/Gameplay/gameplay"));
const TeamRef = lazy(() => import("../Components/Team/team"));
const CareerApplyRef = lazy(() => import("../Components/ApplyDev/applyForm"));

export default function CustomRoutes() {
    return (
        <BrowserRouter>
            <div className="relative-container">
                <Header />
                <AnimatePresence mode="wait">
                    <Routes>
                        <Route
                            key="home"
                            path="/home"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Transition key={"home"}>
                                        <HomePageRef />
                                    </Transition>
                                </Suspense>
                            }
                        />
                        <Route
                            key="privacy_policy"
                            path="/privacy_policy"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Transition key={"privacy"}>
                                        <PrivacyPolicyRef />
                                    </Transition>
                                </Suspense>
                            }
                        />
                        <Route
                            key="terms_and_conditions"
                            path="/terms_and_conditions"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Transition key={"terms"}>
                                        <TermsAndConditionsRef />
                                    </Transition>
                                </Suspense>
                            }
                        />
                        <Route
                            key="risk_disclaimer"
                            path="/risk_disclaimer"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Transition key={"risk"}>
                                        <RiskDisclaimerRef />
                                    </Transition>
                                </Suspense>
                            }
                        />
                        <Route
                            key="content_creator_form"
                            path="/content-creator-form"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Transition key={"content"}>
                                        <ContentCreatorRef />
                                    </Transition>
                                </Suspense>
                            }
                        />
                        <Route
                            key="roadmap"
                            path="/roadmap"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Transition key={"roadmap"}>
                                        <RoadMapRef />
                                    </Transition>
                                </Suspense>
                            }
                        />
                        <Route
                            key="gameplay"
                            path="/gameplay"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Transition key={"gameplay"}>
                                        <GamePlayRef />
                                    </Transition>
                                </Suspense>
                            }
                        />
                        <Route
                            key="career"
                            path="/career-apply"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Transition key={"career"}>
                                        <CareerApplyRef />
                                    </Transition>
                                </Suspense>
                            }
                        />
                        <Route
                            key="team"
                            path="/team"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Transition key={"team"}>
                                        <TeamRef />
                                    </Transition>
                                </Suspense>
                            }
                        />
                        <Route
                            path="/*"
                            element={<Navigate to="/home" />}
                        />
                    </Routes>
                </AnimatePresence>
            </div>
        </BrowserRouter>
    );
}