import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
function NotificationHandler() {
    const { enqueueSnackbar } = useSnackbar();
    const notification = useSelector((state) => state.NotificationHandlerReducer);

    useEffect(() => {
        if (notification.notification != "") {
            enqueueSnackbar(notification.notification, {
                variant: notification.type ? notification.type : "success",
            });
        }
    }, [notification]);
    return null;
}

export default NotificationHandler;
