import * as React from "react";
import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Paper, Typography } from '@mui/material';
import Typewriter from 'typewriter-effect';
import { isMobile } from "react-device-detect";
import Navbar from "../Navbar/navbar";
import './header.sass';
import { current } from "@reduxjs/toolkit";

export default function Header() {
    const currentTheme = useSelector((state) => state.UserDataReducer.theme);
    return (
        <div className="header">
            <div className="parent">
                <img className="logo" src={require('../../Assets/Logo.png')} alt="Logo" />
                <span className="title">
                    Age&nbsp;<span style={{ color: currentTheme ? 'white' : 'black' }}>Of</span>&nbsp;Dinos
                </span>
                <Navbar />
            </div>
        </div>
    );
}