import { motion, AnimatePresence } from "framer-motion";
import "./transition.sass";

function Transition({ children }) {
    return (
        <>
            <motion.div
                key="slide-in"
                className="slide-in"
                initial={{ scaleY: 0 }}
                animate={{ scaleY: 0 }}
                exit={{ scaleY: 1 }}
                transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
            />
            {children}
            <motion.div
                key="slide-out"
                className="slide-out"
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0 }}
                exit={{ scaleY: 0 }}
                transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
                />
            </>
    );
}
export default Transition;
