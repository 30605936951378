import { setCookies, getCookies } from '../../Components/Cookies/cookies';

const themeCookieValue = getCookies('theme');
const defaultThemeValue = true;

const userInitialState = {
    scrollLocked: true,
    theme: true
    //theme: (themeCookieValue !== null && typeof(themeCookieValue) !== undefined) ? themeCookieValue : defaultThemeValue,
};

export const UserDataReducer = (state = userInitialState, action) => {
    switch (action.type) {
        case "SET_SCROLLLOCKED":
            return { ...state, scrollLocked: action.data };
        case "SET_THEME":
            setCookies('theme', action.data, { expires: 365 });
            return { ...state, theme: action.data };
        default:
            return state;
    }
};
