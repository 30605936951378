import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { NotificationHandlerReducer } from "./Reducers/notificationHandlerReducer";
import { UserDataReducer } from "./Reducers/userDataReducer";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";


export const RESET_STORE = () => ({
    type: "RESET",
    data: {},
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["UserDataReducer"],
};

const middleware = [thunk, logger];
const combinedReducer = combineReducers({
    NotificationHandlerReducer: NotificationHandlerReducer,
    UserDataReducer: UserDataReducer
});
const rootReducer = (state, action) => {
    if (action.type === "RESET") state = undefined;
    return combinedReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: middleware,
});
export const persistor = persistStore(store);

