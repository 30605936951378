const initialState = { notification: "", type: "" };

export const NotificationHandlerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_NOTIFICATION": {
            const { notification, type } = action.data;
            return {
                notification: notification,
                type: type,
            };
        }
        case "RESET_NOTIFICATION": {
            return initialState;
        }
        default: {
            return state;
        }
    }
};