import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Button } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import { SET_THEME } from '../../Redux/Actions/userDataAction';
import { useNavigate, useLocation } from 'react-router-dom';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { isMobile } from 'react-device-detect';
import { setCookies } from '../Cookies/cookies';
import './navbar.css'


const navigationItems = [
    { id: "home", label: "Home" },
    { id: "dinopet", label: "Dinosaurs" },
    { id: "land", label: "Land" },
    { id: "economy", label: "Economy" },
    { id: "footer", label: "Social" },
    { id: "faq", label: "FAQ", textTransform: "uppercase" },
];

const Navbar = () => {
    const dispatch = useDispatch();
    const homeLink = '/home';
    const navigate = useNavigate();
    const currentTheme = useSelector((state) => state.UserDataReducer.theme);
    const [showNavbar, setShowNavbar] = useState(false);
    const [isDarkMode, setDarkMode] = useState(currentTheme);
    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    };
    const toggleDarkMode = () => {
        setDarkMode(!isDarkMode);
        dispatch(SET_THEME(!isDarkMode));
    };
    const scrollToSection = async(sectionId) => {
        await handlePath();
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'auto' });
        }
        if (showNavbar) setShowNavbar(false);
    };

    const handlePath = async() => {
        if (window.location.pathname === homeLink) return;
        else {
            navigate(homeLink);
            return;
        }
    };

    useEffect(() => {
        if (currentTheme) {
            document.querySelector('body').classList.remove('light-theme');
            document.querySelector('body').classList.add('dark-theme');
        }
        else {
            document.querySelector('body').classList.remove('dark-theme');
            document.querySelector('body').classList.add('light-theme');
        }
    }, [currentTheme]);

    return (
        <div className="navbar">
            <div className="container">
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <IconButton className="menu-button" style={{
                        color: showNavbar ? "#fd115c" : currentTheme ? 'white' : 'black',
                    }}>
                        {showNavbar ? <MenuOpenOutlinedIcon /> : <MenuOutlinedIcon />}
                    </IconButton>
                </div>
                <div className={`nav-elements  ${showNavbar && 'active'}`} style={{
                    backgroundColor: currentTheme ? '#111111' : '#fbfbfb',
                }}>
                    <ul>
                        {navigationItems.map((item) => (
                            <li key={item.id} style={{
                                borderBottom: isMobile && !currentTheme && '1px solid rgba(0,0,0,0.5)'
                            }}>
                                <Button
                                    className={`nav-buttons ${currentTheme ? 'dark-hover' : 'light-hover'}`}
                                    style={{
                                        textTransform: item.textTransform || "none",
                                    }}
                                    onClick={() => scrollToSection(item.id)}
                                >
                                    {item.label}
                                </Button>
                            </li>
                        ))}
                        <li>
                            <div className='darkmode'>
                                <DarkModeSwitch
                                    style={{
                                        margin: "0 0.5rem"
                                    }}
                                    checked={isDarkMode}
                                    onChange={toggleDarkMode}
                                    size={20}
                                />
                            </div>
                        </li>
                        <li>
                            <Button className='play-button'>
                                Play Now
                            </Button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Navbar