import Cookies from 'js-cookie';

const setCookies = (name, value, daysToExpire) => {
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    Cookies.set(name, value, { expires: date });
};

const getCookies = (name) => {
    return Cookies.get(name);
};

const deleteCookies = (name) => {
    Cookies.remove(name);
};

export { setCookies, getCookies, deleteCookies };